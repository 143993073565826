<template>
  <main class="container">
    <router-view />
  </main>
</template>

<style>
* {
  box-sizing: border-box;
}

html {
  font-size: 1rem;
}

body {
  line-height: 1.5;
  font-family: -apple-system,
               BlinkMacSystemFont,
               "Segoe UI",
               "Roboto",
               "Helvetica Neue",
               Arial,
               sans-serif,
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol";
  color: #555;
}

.container {
margin: 2rem auto;
padding: 2rem;
border-radius: 0.4rem;
border: 1px solid #AAA;
/* box-shadow: 5px 5px 10px 0 #CCC; */
box-shadow:
      0 1px 1px #CCC,
      1px 2px 2px #CCC,
      2px 4px 4px #CCC,
      4px 8px 8px #CCC,
      8px 16px 16px #CCC
    ;
width: 38rem;
max-width: 100%;
}

.logo {
  display: block;
  margin: 0 auto 1.2rem;
  height: 50px;
  max-width: 100%;
}

.title {
  font-size: 1.5rem;
}

p {
  margin: 1rem 0;
}

form {
  margin: 2.5rem auto 0;
  width: 19rem;
  max-width: 100%;
}

a:focus {
  outline: 2px solid dodgerblue;
}

a:focus:not(:focus-visible){
  outline: none;
}

@media screen and ( max-width: 40rem ) {
  body {
    margin: 0;
  }

  .container {
    margin: 0 auto;
    border: none;
    box-shadow: none;
  }
}
</style>
