export default {
  "ui": {
    "acceptPp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J’ai lu et j’accepte la <a href=\"", _interpolate(_named("ppUrl")), "\" target=\"_blank\">politique de protection des données</a>."])},
    "acceptTac": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["J’accepte les <a href=\"", _interpolate(_named("tacUrl")), "\" target=\"_blank\">conditions générales</a>."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
    "operationSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procédure réussie"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de passe"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traîtement..."])},
    "regCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code d’enregistrement"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’enregistrer"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répéter le mot de passe"])},
    "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le mot de passe"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "sendAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer de nouveau"])},
    "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un lien"])}
  },
  "clientError": {
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien incorrect"])},
    "operationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echec de la procédure"])},
    "pwdsDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe ne sont pas identiques."])},
    "pwdTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 8 caractères."])},
    "regInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet enregistrement est inactif. Soit il n’a pas encore été activé, soit il a déjà expiré."])},
    "useEntireLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez utiliser le lien complet que vous avez reçu."])},
    "veriFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de la vérification"])}
  },
  "serverMsg": {
    "mailResent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>L’e-mail a été envoyé une nouvelle fois.</strong> Le lien est valable 30 minutes. Si l’e-mail n’apparaît pas dans votre boîte de réception, veuillez également vérifier votre dossier de spam.<br><br>Vous pouvez maintenant fermer cet onglet."])},
    "newpassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez créer un nouveau mot de passe sécurisé pour votre accès."])},
    "regMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>Nous vous remercions de votre inscription !</strong> Nous vous avons envoyé un e-mail à cette adresse : <strong>", _interpolate(_named("email")), "</strong>. Veuillez suivre le lien contenu dans l’e-mail pour terminer l’enregistrement. Le lien est valide pendant 30 minutes.<br>Si vous n’avez pas reçu l’e-mail, veuillez vérifier que vous avez saisi l’adresse correcte et consultez votre dossier de courrier indésirable.<br>e-mail <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Renvoyer l’e-mail</a> ?<br><br>Vous pouvez maintenant fermer cet onglet."])},
    "resetDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre adresse e-mail. Nous vous enverrons un lien qui vous permettra de créer un nouveau mot de passe."])},
    "resetMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>L’e-mail a été envoyé !</strong> Nous vous avons envoyé un e-mail à cette adresse : <strong>", _interpolate(_named("email")), "</strong> Veuillez suivre le lien contenu dans l’e-mail pour créer un nouveau mot de passe. Le lien est valide pendant 30 minutes.<br>Si vous n’avez pas reçu l’e-mail, veuillez vérifier que vous avez saisi l’adresse correcte et consultez votre dossier de courrier indésirable.<br>e-mail <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Renvoyer l’e-mail</a> ?<br><br>vous pouvez maintenant fermer cet onglet."])},
    "resetSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Votre mot de passe a été modifié.</strong>"])},
    "veriSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>La vérification a été effectuée avec succès.</strong>"])}
  },
  "serverError": {
    "accountAlreadyUsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Il existe déjà un enregistrement avec cette adresse e-mail. <a href=\"/reset/id/", _interpolate(_named("appId")), "?lang=", _interpolate(_named("lang")), "\">Réinitialiser le mot de passe</a> ?"])},
    "invalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des données incorrectes ont été transmises."])},
    "invalidRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Code d’enregistrement incorrect.</strong> Il n’y a pas de page d’enregistrement associée à ce code."])},
    "invalidRegLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Lien d’enregistrement incorrect.</strong> Veillez à copier le lien complet."])},
    "invalidRegPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Page d’enregistrement incorrecte.</strong> Si vous avez cliqué sur un lien qui vous a redirigé vers cette page, veuillez contacter le support."])},
    "invalidResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Lien de réinitialisation incorrect.</strong> Veuillez faire attention à bien copier le lien en entier. Il se peut que vous ayez déjà changé votre mot de passe ou que le lien de réinitialisation ait expiré."])},
    "invalidResetPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Page de réinitialisation incorrecte.</strong> Si vous avez cliqué sur un lien qui vous a redirigé ici, veuillez contacter le support."])},
    "invalidVeriLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Lien de vérification incorrect.</strong> Veuillez faire attention à copier l’URL entière. Il se peut que vous ayez déjà vérifié votre inscription ou que le lien de vérification ait expiré."])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n’existe pas de compte d’utilisateur avec cette adresse e-mail."])},
    "noPendingReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune réinitialisation en attente n’a été trouvée. Soit elle a déjà expiré, soit elle a déjà été effectué."])},
    "noPendingVeri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune vérification en attente n’a été trouvée. Soit elle a déjà expiré, soit elle a déjà été effectuée."])},
    "notOnAllowList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail ne figure pas dans la liste des adresses autorisées. Veuillez utiliser la même adresse e-mail que celle à laquelle l’invitation à l’inscription a été envoyéex."])},
    "resetLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un e-mail contenant le lien de réinitialisation a déjà été envoyé. <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Renvoyer l’e-mail</a> ?"])},
    "unknownServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de serveur inconnue. Veuillez réessayer. Si l’erreur persiste, veuillez contacter le support."])},
    "veriLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Un e-mail a déjà été envoyé avec le lien de vérification. <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Renvoyer l’e-mail</a> ?"])},
    "waitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez attendre au moins une minute avant d’envoyer à nouveau l’e-mail."])},
    "waitRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez attendre au moins 30 secondes avant de renvoyer le code d’enregistrement."])}
  }
}