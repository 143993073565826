import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'RootPage',
    component: () => import('@/views/RootPage.vue')
  },
  {
    path: '/register/:regId/:email?',
    name: 'Registration',
    component: () => import('@/views/RegistrationPage.vue')
  },
  {
    path: '/register/id/:appId',
    name: 'Regcode',
    component: () => import('@/views/RegcodePage.vue')
  },
  {
    path: '/verify/:veriToken',
    name: 'Verification',
    component: () => import('@/views/VerificationPage.vue')
  },
  {
    /* :id kann je nach :type regId oder appId sein */
    path: '/resend/:type/:id/:email',
    name: 'Resend',
    component: () => import('@/views/ResendPage.vue')
  },
  {
    path: '/reset/id/:appId/:email?',
    name: 'Reset',
    component: () => import('@/views/ResetPage.vue')
  },
  {
    path: '/reset/:resetToken',
    name: 'Newpass',
    component: () => import('@/views/NewpassPage.vue')
  },
  {
    /* Alle Pfade (auch mit mehreren "/"), die oben nicht definiert sind */
    path: '/:notFound(.*)*',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
