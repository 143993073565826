export default {
  "ui": {
    "acceptPp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ho letto e compreso la <a href=\"", _interpolate(_named("ppUrl")), "\" target=\"_blank\">politica sul trattamento dei dati personali</a> e la accetto."])},
    "acceptTac": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accetto le <a href=\"", _interpolate(_named("tacUrl")), "\" target=\"_blank\">condizioni generali</a>."])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["indirizzo mail"])},
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logo"])},
    "operationSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo riuscito"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password - parola d’ordine"])},
    "processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elaborazione in corso"])},
    "regCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codice di registrazione"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registrare"])},
    "repeatPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ripetere password"])},
    "savePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["salvare password"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inviare"])},
    "sendAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inviare nuovamente"])},
    "sendLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inviare link"])}
  },
  "clientError": {
    "linkInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["link non valido"])},
    "operationFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operazione fallita"])},
    "pwdsDontMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le password non corrispondono."])},
    "pwdTooShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve contenere almeno 8 caratteri."])},
    "regInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa registrazione è inattiva. O non è ancora stata attivata o è già scaduta."])},
    "useEntireLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfavore utilizzate il link completo che avete ricevuto."])},
    "veriFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica fallita"])}
  },
  "serverMsg": {
    "mailResent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>L’e-mail è stata inviata di nuovo.</strong> Il link è valido per 30 minuti. Se l’e-mail non compare nella casella di posta, controllare anche la cartella spam. <br><br> Ora è possibile chiudere questa scheda."])},
    "newpassDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, creare una nuova password sicura per l’accesso."])},
    "regMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>", _interpolate(_named("email")), "</strong><br><strong>Grazie per la vostra iscrizione!</strong> Le abbiamo inviato un’e-mail all’indirizzo che ha inserito. Segua il link contenuto nell’e-mail per completare la registrazione. Il link è valido per 30 minuti. Se non avete ricevuto l’e-mail, controllate la cartella spam.<br>E-Mail <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Inviare nuovamente la Mail.</a>?<br><br> Ora è possibile chiudere questa scheda."])},
    "resetDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, inserite il vostro indirizzo e-mail. Vi invieremo un link per creare una nuova password."])},
    "resetMailSent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<strong>L’e-mail è stata inviata!</strong> Per favore seguite il link contenuto nell’e-mail per creare una nuova password. Il link è valido per 30 minuti. Se non avete ricevuto l’e-mail, controllate la cartella spam. <br>E-Mail <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">inviare nuovamente</a>?<br><br>Ora è possibile chiudere questa scheda."])},
    "resetSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>La password è stata modificata.</strong>"])},
    "veriSuccessful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>La verifica è riuscita.</strong>"])}
  },
  "serverError": {
    "accountAlreadyUsed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Esiste già una registrazione con questo indirizzo e-mail. <a href=\"/reset/id/", _interpolate(_named("appId")), "?lang=", _interpolate(_named("lang")), "\">Reimpostare la password</a>?"])},
    "invalidData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono stati trasmessi dati non validi."])},
    "invalidRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Codice di registrazione non valido.</strong> Non esistono pagine di registrazione collegate a questo codice."])},
    "invalidRegLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Link di registrazione non valido.</strong> Per favore controllate di aver copiato il link completo."])},
    "invalidRegPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Pagina di registrazione non valida.</strong> Se avete cliccato su un link che vi ha portato qui, rivolgetevi all’amministratore."])},
    "invalidResetLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Link di ripristino non valido.</strong> Controllate di aver copiato il link per intero. Eventualmente avete modificato la password o il link di ripristino è scaduto. "])},
    "invalidResetPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Pagina di ripristino non valida.</strong> Se avete cliccato un link che vi ha portato qui, contattate l’amministratore. "])},
    "invalidVeriLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Link di verifica non valido.</strong> Controllate di copiare la URL per intero. Eventualmente avete appena verificato la vostra registrazione, o il vostro link di verifica è scaduto. "])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non esiste un utente con questo indirizzo mail."])},
    "noPendingReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stato trovato alcun ripristino in sospeso. O è già scaduto o è già stato eseguito. "])},
    "noPendingVeri": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata trovata nessuna verifica in sospeso. O è scaduta o si è già conclusa. "])},
    "notOnAllowList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il vostro indirizzo Mail non risulta tra gli indirizzi autorizzati. Utilizzate lo stesso indirizzo mail al quale è stato inviato l’invito per la registrazione."])},
    "resetLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["É stata appena inviata una Mail con il link di ripristino. <a href=\"/resend/reset-mail/", _interpolate(_named("appId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Inviare nuovamente la Mail.</a>"])},
    "unknownServerError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore sconosciuto. Ritentare per favore. Se l’errore persiste rivolgersi all’amministratore. "])},
    "veriLinkAlreadySent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["È stata appena inviata una Mail con il link di verifica. <a href=\"/resend/verification-mail/", _interpolate(_named("regId")), "/", _interpolate(_named("email")), "?lang=", _interpolate(_named("lang")), "\">Inviare nuovamente la Mail.</a>"])},
    "waitEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, aspettare almeno un minuto prima di inviare nuovamente la Mail"])},
    "waitRegCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, aspettare almeno 30 secondi prima di inviare nuovamente il codice di registrazione"])}
  }
}